/* SF Pro Display */
@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Ultralight.woff2') format('woff2'), 
         url('./fonts/SFProDisplay/SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Light.woff2') format('woff2'), 
         url('./fonts/SFProDisplay/SFProDisplay-Light.woff') format('woff');
    font-weight: 300;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Regular.woff2') format('woff2'), 
         url('./fonts/SFProDisplay/SFProDisplay-Regular.woff') format('woff');
    font-weight: 400;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Medium.woff2') format('woff2'), 
         url('./fonts/SFProDisplay/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Semibold.woff2') format('woff2'),
         url('./fonts/SFProDisplay/SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-display: block;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: local('SF Pro Display'), 
         url('./fonts/SFProDisplay/SFProDisplay-Bold.woff2') format('woff2'),
         url('./fonts/SFProDisplay/SFProDisplay-Bold.woff') format('woff');
    font-weight: 700;
    font-display: block;
    font-style: normal;
}
