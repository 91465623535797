.controls {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
}

.controls .MuiSlider-rail {
    opacity: 1;
}

.controls .MuiSlider-track {
    color: #F4C115;
}

.controls .MuiSlider-root {
    color: black;
    height: 2px;
}

.controls .MuiSlider-thumb {
    color: #F4C115;
    width: 15px;
    height: 15px;
}

.cropper-container {
    position: relative;
    width: 100%;
    height: 300px;
}
